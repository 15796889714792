$bucket: 'https://dev-front-modular-assets.s3.us-east-2.amazonaws.com';

@import './fonts';
@import './components/layout';

:root {
  --fm-color-black: #000000;
  --fm-color-black-light: #666666;
  --fm-color-black-lighten: #757575;

  --fm-color-gray: #e0e0e0;
  --fm-color-gray-light: #f4f6f9;
  --fm-color-gray-dark: #b5b5b5;

  --fm-color-yellow: #ffaf00;
  --fm-color-yellow-light: #fef3c7;

  --fm-color-green: #60d34c;
  --fm-color-green-light: #cdf1c6;

  --fm-color-red: #ff4065;
  --fm-color-red-light: #fee2e2;

  --fm-color-white: #ffffff;

  --fm-color-blue: #60A5FA;
  --fm-color-blue-light: #dbeafe;
  --fm-color-blue-dark: #0d192b;

  --fm-color-purple-light: #dccfed;
  --fm-color-purple-lighten: #efe8f7;

  --fm-color-primary: #5010a6;
  --fm-color-secondary: #5346ff;
  --fm-color-success: #60d34c;
  --fm-color-error: #ff4065;

  --fm-color-warning: #f8bb86;
  --fm-color-info: #3fc3ee;
  --fm-color-default: #87adbd;

  --fm-font-family: 'Open Sans', sans-serif;
  --fm-font-title-family: Gotham, 'Open Sans', sans-serif;

  --fm-padding-size: 16px;
}

a,
:visited {
  text-decoration: none !important;
  color: inherit !important;
}

.bg-primary {
  background: #2a006d !important;
  border-color: #2a006d !important;
}
.bg-secondary {
  background-color: var(--fm-color-gray-light);
}
.absolute {
  position: absolute !important;
}
.fixed {
  position: fixed !important;
}
.relative {
  position: relative !important;
}
.sticky {
  position: sticky !important;
}
.vh100 {
  min-height: 100vh !important;
}
.h-100 {
  height: 100% !important;
}
.w-100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer !important;
}
.text-no-wrap {
  white-space: nowrap !important;
}
.wrap_app {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  & > div {
    min-height: 100vh;
    &:first-child {
      padding-top: 64px;
    }
  }
}
.text {
  &-primary {
    color: #2a076d;
  }
  &-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.btn-secondary {
  background-color: #5346ff !important;
  border-color: #5346ff !important;
  color: white !important;
}

.v-application {
  & > div.v-application--wrap {
    min-height: auto !important;
  }
}
.v-pagination {
  &.theme--light {
    .v-pagination__navigation,
    .v-pagination__item {
      box-shadow: none;
      color: var(--fm-color-black);
      font-size: 0.75em;
      .v-icon {
        color: var(--fm-color-black);
      }
      &.v-pagination__item--active {
        color: var(--fm-color-white);
        font-weight: 900 !important;
        font-family: var(--fm-font-title-family);
      }
    }
  }
}
