$fontGotham: $bucketFont + '/gotham';

@font-face {
  font-family: Gotham;
  src: url($fontGotham + '/gotham-bold.woff2') format('woff2'), url($fontGotham + '/gotham-bold.ttf') format('truetype'),
    url($fontGotham + '/gotham-bold.svg#gothambold') format('svg');
  font-weight: 700;
}

@font-face {
  font-family: Gotham;
  src: url($fontGotham + '/gotham-medium.woff2') format('woff2'),
    url($fontGotham + '/gotham-medium.ttf') format('truetype'),
    url($fontGotham + '/gotham-medium.svg#gothammedium') format('svg');
  font-weight: 500;
}

@font-face {
  font-family: Gotham;
  src: url($fontGotham + '/gotham-book.woff2') format('woff2'), url($fontGotham + '/gotham-book.ttf') format('truetype'),
    url($fontGotham + '/gotham-book.svg#gothambook') format('svg');
  font-weight: 400;
}
