@font-face {
    font-family: "fm-icons";
    src: url("./fm-icons.svg?ef7f0165283974eaf0287621f1a8ab58#fm-icons") format("svg"),
url("./fm-icons.ttf?ef7f0165283974eaf0287621f1a8ab58") format("truetype"),
url("./fm-icons.woff2?ef7f0165283974eaf0287621f1a8ab58") format("woff2"),
url("./fm-icons.woff?ef7f0165283974eaf0287621f1a8ab58") format("woff"),
url("./fm-icons.eot?ef7f0165283974eaf0287621f1a8ab58#iefix") format("embedded-opentype");
}

i[class^="fm-icon-"]:before, i[class*=" fm-icon-"]:before {
    font-family: fm-icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fm-icon-apps:before {
    content: "\f101";
}
.fm-icon-calendar:before {
    content: "\f102";
}
.fm-icon-campaigns:before {
    content: "\f103";
}
.fm-icon-cupon:before {
    content: "\f104";
}
.fm-icon-delete:before {
    content: "\f105";
}
.fm-icon-edit:before {
    content: "\f106";
}
.fm-icon-environments:before {
    content: "\f107";
}
.fm-icon-flags:before {
    content: "\f108";
}
.fm-icon-foodcoins:before {
    content: "\f109";
}
.fm-icon-halls:before {
    content: "\f10a";
}
.fm-icon-menu:before {
    content: "\f10b";
}
.fm-icon-platforms:before {
    content: "\f10c";
}
.fm-icon-product:before {
    content: "\f10d";
}
.fm-icon-qr:before {
    content: "\f10e";
}
.fm-icon-schedules:before {
    content: "\f10f";
}
.fm-icon-search:before {
    content: "\f110";
}
.fm-icon-settings:before {
    content: "\f111";
}
.fm-icon-stores:before {
    content: "\f112";
}
