@import '../mixins/responsive';

.fm-layout {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100%;
  padding: calc(var(--fm-padding-size) * 1.5) 0;
  .fm-sidemenu {
    margin-bottom: calc(var(--fm-padding-size) * 1.5);
    width: 100%;
  }
  .fm-main {
    .fm-main__content {
      height: 100%;
    }
    .fm-main__container {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
  .fm-section-header {
    margin-bottom: var(--fm-padding-size);
  }
  .fm-section-content {
    background-color: var(--fm-color-white);
    border-radius: var(--fm-padding-size);
    flex: 1;
    &__layout {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: var(--fm-padding-size);
      background-color: var(--fm-color-white);
      padding: 0;
    }
    &__body {
      &--padding {
        padding: calc(var(--fm-padding-size) * 1.5);
      }
      height: 100%;
    }
    &__footer {
      width: 100%;
      border-top: 1px solid var(--fm-color-gray);
      padding: calc(var(--fm-padding-size) * 1.5);
      &--flex-start {
        align-self: flex-start;
      }
    }
    &:not(.fm-section-content__layout) {
      padding: calc(var(--fm-padding-size) * 1.5);
    }
  }

  .fm-section-area {
    margin: calc(var(--fm-padding-size) * 1.5) 0;
    &:first-child {
      margin-top: 0;
    }
    &.last-section-area,
    &:last-child {
      margin-bottom: 0;
    }
  }
  .fm-chip {
    text-transform: capitalize !important;
    min-width: calc(var(--fm-padding-size) * 5);
    justify-content: center;
    &.active {
      background-color: rgba(95, 211, 74, 0.12) !important;
      color: var(--fm-color-success) !important;
    }
    &.inactive {
      background-color: #ffe8ec !important;
      color: var(--fm-color-error) !important;
    }
  }

  .fm-button-float {
    bottom: calc(var(--fm-padding-size) * 3);
    left: 100%;
    z-index: 1;
    max-width: fit-content;
    @media (min-width: 600px) {
      padding-right: 20px;
    }
    .v-btn {
      &.mobile {
        min-width: 20px !important;
        max-width: 20px !important;
        height: 45px !important;
        .v-icon {
          margin: 0 !important;
        }
      }
    }
  }

  @include medium {
    flex-direction: row;
    .fm-sidemenu {
      margin-bottom: 0;
      margin-right: calc(var(--fm-padding-size) * 1.5);
      width: 64px;
    }
    .fm-main {
      width: 100%;
    }
  }
}
